
import { Component, Vue } from "vue-property-decorator";
import { IPatientProfileCreate } from "@/interfaces";
import { dispatchCreatePatient } from "@/store/admin/actions";
import { commitSwitchLanguage } from "@/store/admin/mutations";
import { readBreeds, readLanguage } from "@/store/admin/getters";
import { readUserProfile } from "@/store/main/getters";
import myUpload from 'vue-image-crop-upload/upload-2.vue';
import { userInfo } from "os";

@Component({
  components: {
    'my-upload': myUpload
  },
})
export default class CreatePatient extends Vue {
  public firstname: string = "";
  public lastname: string = "";
  public nurse_id: number = 0;
  public isActive: boolean = false;
  public gender: string = "";
  public road: string = "";
  public house_number: string = "";
  public postcode: number = 0;
  public city: string = "";
  public birthplace: string = "";
  public birthday: Date = new Date(1900, 1, 1);
  public nationality: string = "";
  public breed: string = "";

  public items = ["stallion", "mare", "geldings"];
  public items_german = ["Hengst", "Stute", "Wallach"];

  public numberRules = [
    (v) => v > 1000 || v < 10000 || "Please enter an valid postcode",
  ];


    
  //For imageCropUpload----------------
  public show = false;
  public imgDataUrl = '';
  public params = {
    token: '123456789',
    name: 'avatar'
  };
  public headers = {
    smail: '*_~'
  }

  public de = {
        hint: 'Click or drag the file here to upload',
        loading: 'Uploading…',
        noSupported: 'Dieser Browser wird nicht unterstützt, bitte benutzen sie IE10+ oder vergleichbare.',
        success: 'Hochladen erfolgreich',
        fail: 'Hochladen fehlgeschlagen',
        preview: 'Vorschau',
        btn: {
          off: 'Abbrechen',
          close: 'Schließen',
          back: 'Zurück',
          save: 'Sichern'
        },
        error: {
          onlyImg: 'Nur Bilder',
          outOfSize: 'Bild ist zu groß. Limit ist: ',
          lowestPx: 'Bilder Größe ist zu gering. Mindestgröße ist: '
        }
  }

  public en = {
        hint: 'Click or drag the file here to upload',
        loading: 'Uploading…',
        noSupported: 'Browser is not supported, please use IE10+ or other browsers',
        success: 'Upload success',
        fail: 'Upload failed',
        preview: 'Preview',
        btn: {
          off: 'Cancel',
          close: 'Close',
          back: 'Back',
          save: 'Save'
        },
        error: {
          onlyImg: 'Image only',
          outOfSize: 'Image exceeds size limit: ',
          lowestPx: 'Image\'s size is too low. Expected at least: '
        }
  }


  get text() {
    if(this.language) {
      return this.en;
    } else return this.de;
  }

  public toggleShow() {
    this.show = !this.show;
  }

	cropSuccess(imgDataUrl, field){
		console.log('-------- crop success --------');
		this.imgDataUrl = imgDataUrl;
	}

  cropUploadSuccess(jsonData, field){
		console.log('-------- upload success --------');
		console.log('field: ' + field);
	}

  cropUploadFail(status, field){
				console.log('-------- upload fail --------');
				console.log(status);
				console.log('field: ' + field);
	}

  //Should not be used at the time -> imgDataUrl is already a b64
  public async b64Helper(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }

  //---------------------------------------

  public reset() {
    this.firstname = "";
    this.lastname = "";
    this.nurse_id = 0;
    this.isActive = false;
    this.gender = "";
    this.road = "";
    this.house_number = "";
    this.postcode = 0;
    this.birthplace = "";
    this.birthday = new Date(1900, 1, 1);
    this.nationality = "";
    this.breed = "";
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  get breeds() {
    return readBreeds(this.$store);
  }

  get creater_id() {
    const id =  (readUserProfile(this.$store))?.id as number;
    return id;
  }

  get valid() {
    if (this.firstname == "") {
      return false;
    }
    return true;
  }

  get language() {
    return readLanguage(this.$store);
  }
  
  public swtichLanguageEng() {
    console.log("Englisch");
    //True means eng
    if(this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  public swtichLanguageDe() {
    //False means de
    console.log("DEUTSCH");
    if(!this.language) {
      commitSwitchLanguage(this.$store);
    }
  }
  
  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedPatient: IPatientProfileCreate = {
        firstname: this.firstname,
        lastname: this.lastname,
      };
      if (this.road) {
        updatedPatient.road = this.road;
      }
      if (this.house_number) {
        updatedPatient.house_number = this.house_number;
      }
      if (this.postcode && this.postcode != 0) {
        updatedPatient.postcode = this.postcode;
      }
      if (this.city) {
        updatedPatient.city = this.city;
      }
      if (this.gender) {
        updatedPatient.gender = this.gender;
      }
      if (this.birthplace) {
        updatedPatient.birthplace = this.birthplace;
      }
      if (this.birthday.toString() != new Date(1900, 1, 1).toString()) {
        updatedPatient.birthday = this.birthday;
      }
      if (this.nationality) {
        updatedPatient.nationality = this.nationality;
      }
      if (this.breed) {
        updatedPatient.breed = this.breed;
      }
      if (this.imgDataUrl) {
        let b64 = this.imgDataUrl;
        try {
          await dispatchCreatePatient(this.$store, {
            patient: updatedPatient,
            file: b64 as string,
            creater_id: this.creater_id,
          });
        } catch (e) {

        }
      } else {
        await dispatchCreatePatient(this.$store, {
          patient: updatedPatient,
          file: "null",
          creater_id: this.creater_id,
        });
      }
      const user = readUserProfile(this.$store)
      if (user?.is_superuser) {
        this.$router.push("/main/horse/users/all");
      } else {
        this.$router.push("/main/dashboard");
      }
    }
  }

}
